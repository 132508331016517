<div id="emp-main" *ngIf="dataLoading">
    <div class="loader">
        <img src="./assets/img/loader.gif" alt="loading...">
    </div>
</div>
<app-people-contract *ngIf="isPeopleContractDisplayed" [archiveFlag]="true" (close)="closePeopleContract()"
    (create)=createNew() (modify)="modify($event)">
</app-people-contract>
<app-move-contract *ngIf="isMoveContractDisplayed" [contractToMove]="contractToMove"
    (closeMoveContract)="closeMoveContract()" (validateMoveContract)="validateMoveContract($event)"></app-move-contract>
<div id="emp-main" class="noselect" *ngIf="!dataLoading">
    <div id="emp-top">
        <div id="emp-left">
            <div id="emp-iden" class="grh-section emp-item">
                <h2 class="grh-section-title">
                    <label>
                        <span>{{dictionary.identity}}</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curEmployeeInfo">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="curEmployeeInfo" >
                    <form [formGroup]="formEmployeeInfo">
                        <div id="emp-iden-cols">
                            
                            <div id="emp-iden-col1">         
                                <div class="emp-iden-field">
                                    <label>{{dictionary.rptName}}</label>
                                    <div class="field-container">
                                        <input type="text" formControlName="lastname">
                                    </div>
                                </div>
                                <div class="emp-iden-field">
                                    <label>{{dictionary.firstname}}</label>
                                    <div class="field-container">
                                        <input type="text" formControlName="firstname">
                                    </div>
                                </div>
                                <div class="emp-iden-field">
                                    <label>{{dictionary.mail}}</label>
                                    <div class="field-container">
                                        <input type="text" formControlName="email">
                                    </div>
                                </div>
                                <div class="emp-iden-field">
                                    <label>{{dictionary.externalMail}}</label>
                                    <div class="field-container">
                                        <input type="text" formControlName="externalemail">
                                    </div>
                                </div>                            
                                <div class="emp-iden-field">
                                    <label>{{dictionary.login}}</label>
                                    <div class="field-container">
                                        <input type="text" formControlName="login">
                                    </div>
                                </div>
                            </div>
                            <div id="emp-iden-col2">
                                <div class="emp-iden-field">
                                    <label>{{dictionary.sex}}</label>
                                    <div class="field-container">
                                        <input type="radio" id="gender-M" formControlName="sexval" value="H">
                                        <label for="gender-M">M</label>
                                        <input type="radio" id="gender-F" formControlName="sexval" value="F">
                                        <label for="gender-F">F</label>
                                    </div>  
                                </div>
                                <div class="emp-iden-field">
                                    <label>{{dictionary.title}}</label>
                                    <div class="field-container">
                                        <select formControlName="titleid">
                                            <option></option>
                                            <option value="1">{{dictionary.madam}}</option>
                                            <option value="2">{{dictionary.mister}}</option>
                                            <option value="3">{{dictionary.miss}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="emp-iden-field">
                                    <label>{{dictionary.mat}}</label>
                                    <div class="field-container">
                                        <input type="text" formControlName="matriculenr">
                                        <button placeholder={{dictionary.btnGenerate}} (click)="generateMatricule()">
                                            <i class="fa fa-cog"></i>
                                           
                                        </button>   <!--LAURENT-->     
                                    </div>                         
                                </div>
                                <div class="emp-iden-field">
                                    <label for="chk-ext-flag">{{dictionary.SF}}</label>
                                    <div class="field-container">
                                        <input type="checkbox" id="chk-ext-flag" formControlName="externalflag">
                                        <input type="text" formControlName="externalcode">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div id="emp-park-off" class="grh-section emp-item" *ngIf="hasPark">
                <h2 class="grh-section-title capitalize" (click)="toggleSection('emp-park-off')">
                    <label>
                        <span *ngIf="!isSectionOpened('emp-park-off')" class="caret"><i
                                class="fa fa-caret-right"></i></span>
                        <span *ngIf="isSectionOpened('emp-park-off')" class="caret"><i
                                class="fa fa-caret-down"></i></span>
                        <span>Parking</span>
                        <!--<span>Parking & flex office</span>-->
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curEmployeeInfo">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="isSectionOpened('emp-park-off')">
                    <div id="emp-park-cols">
                        <div id="emp-park-col1">
                            <div class="emp-park-field">
                                <label>Parking Pool</label>
                                <select [disabled]="isRO" [(ngModel)]="curEmployeeInfo.park_pool_id">
                                    <option *ngFor="let pool of curParkPools" [ngValue]="pool.pool_id">
                                        {{pool.park_pool_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div id="emp-park-col2" *ngIf="false">
                        </div>
                    </div>
                </div>
            </div>
            <div id="emp-serv" class="grh-section emp-item ">
                <h2 class="grh-section-title capitalize" (click)="toggleSection('emp-serv')">
                    <label>
                        <span *ngIf="!isSectionOpened('emp-serv')" class="caret"><i
                                class="fa fa-caret-right"></i></span>
                        <span *ngIf="isSectionOpened('emp-serv')" class="caret"><i class="fa fa-caret-down"></i></span>
                        <span>{{dictionary.services}}</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="isSectionOpened('emp-serv')">
                        <div class="emp-serv-list" *ngFor="let contract of curContracts">
                            <div class="grh-section-undertitle" [class.new]="contract.person_contract_id == -1">
                                <label>{{contract.service_name}}</label>
                                <div class="actions" *ngIf="!isRO && checkMoveContract(contract)">
                                    <span class="action-item" (click)="showMoveContract(contract)"> {{dictionary.move}}</span>
                                </div>
                            </div>
                            <div class="grh-ctrt-content">
                                <div class="emp-mult-fields">
                                    <div class="emp-left-field">
                                        <label class="label">{{dictionary.company}}</label>
                                        <label class="display-content">{{contract.company_name}}</label>
                                    </div>
                                    <div class="emp-right-field">
                                        <label class="label" [class.ng-invalid]="contractMasterFlagInvalid[contract.person_contract_id]" for="chk-master-serv-{{contract.person_contract_id}}">{{dictionary.masterService}}</label>
                                        <input type="checkbox" id="chk-master-serv-{{contract.person_contract_id}}" [disabled]="isRO" [(ngModel)]="contract.master_flag" (change)="checkDataFields()">
                                    </div>
                                </div>
                                <div class="emp-field">
                                    <label class="label">{{dictionary.department}}</label>
                                    <label class="display-content">{{contract.department_name}}</label>
                                </div>
                                <div class="emp-field">
                                    <label class="label">{{dictionary.status}}</label>
                                    <select [(ngModel)]="contract.contract_status_id" [disabled]="!newContract" [class.ng-invalid]="contractStatusInvalid[contract.person_contract_id]" (change)="checkDataFields()">
                                        <option value="1">{{dictionary.scale}}</option>
                                        <option value="2">{{dictionary.offScale}}</option>
                                        <option value="3">{{dictionary.pigist}}</option>
                                        <option value="4">{{dictionary.intern}}</option>
                                        <option value="5">{{dictionary.apprentice}}</option>
                                        <option value="7">{{dictionary.menuLabelPlanning}}</option>
                                    </select>
                                </div>
                                <div class="emp-field">
                                    <label class="label">{{dictionary.entryDate}}</label>
                                    <input type="date" [class.ng-invalid]="contractFromDateInvalid[contract.person_contract_id]" [(ngModel)]="contract.from_date" value="contract.from_date" [disabled]="!newContract && contract.from_date != null && checkContractDate(contract.from_date)" (change)="checkDataFields()">
                                </div>
                                <div class="emp-field">
                                    <label class="label">{{dictionary.outDate}}</label>
                                    <input type="date" [(ngModel)]="contract.to_date" [disabled]="isRO || (contract.to_date != null && !contract.to_date_modified && checkContractDate(contract.to_date))" (change)="contract.to_date_modified = true" value="contract.to_date" (change)="checkDataFields()">
                                </div>
                                <div class="emp-field">
                                    <label class="label">{{dictionary.workRates}}</label>
                                    <label *ngIf="!newEmployee && !newContract">{{contract.etp_contract_val}} %</label>
                                    <input type="number" *ngIf="newEmployee != null || newContract != null" [disabled]="isRO"  [(ngModel)]="contract.etp_contract_val">
                                </div>
                                <div class="emp-field" *ngIf="hasTS">
                                    <label class="label">{{dictionary.initPresta}}</label>
                                    <select [disabled]="isRO" [(ngModel)]="contract.prest_init_val">
                                        <option value="POT">{{dictionary.menuLabelPlanning}}</option>
                                        <option value="SERVICE">{{dictionary.rptServ}}</option>
                                        <option value="PERSON">{{dictionary.rptPers}}</option>
                                        <option value="NO">{{dictionary.nothing}}</option>
                                    </select>
                                </div>
                                <div class="emp-field">
                                    <label class="label">Parameters</label>
                                    <div>
                                        <div *ngIf="hasTS">
                                            <input type="checkbox" id="chk-period-flag-{{contract.person_contract_id}}" [disabled]="isRO" [(ngModel)]="contract.prest_period_flag">
                                            <label for="chk-period-flag-{{contract.person_contract_id}}">{{dictionary.periodeEncoding}}</label>
                                        </div>
                                        <div *ngIf="hasTS"> 
                                            <input type="checkbox" id="chk-shiftwork-flag-{{contract.person_contract_id}}" [disabled]="isRO" [(ngModel)]="contract.shift_work_flag" [disabled]="!newContract">
                                            <label for="chk-shiftwork-flag-{{contract.person_contract_id}}">Shift work</label>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>                         
                </div>
            </div>
            <div id="emp-var" class="grh-section emp-item">
                <h2 class="grh-section-title" (click)="toggleSection('emp-var')">
                    <label>
                        <span *ngIf="!isSectionOpened('emp-var')" class="caret"><i class="fa fa-caret-right"></i></span>
                        <span *ngIf="isSectionOpened('emp-var')" class="caret"><i class="fa fa-caret-down"></i></span>
                        <span>{{dictionary.labelOther}}</span>
                    </label>
                </h2>
                <div class="grh-section-content" *ngIf="!curEmployeeInfo">
                    <span>No data ! </span>
                </div>
                <div class="grh-section-content" *ngIf="curEmployeeInfo && isSectionOpened('emp-var')">
                    <div id="emp-var-cols">
                        <div id="emp-var-col1">
                            <div class="emp-field">
                                <label>{{dictionary.connection}}</label>
                                <select [disabled]="isRO" [(ngModel)]="curEmployeeInfo.tms_connection_mode_id"
                                    (change)="changeConnectionMode()">
                                    <option *ngFor="let conn of curConnectionModes"
                                        [ngValue]="conn.tms_connection_mode_id">
                                        {{conn.name}}</option>
                                </select>
                                <button *ngIf="curEmployeeInfo.tms_connection_mode_id == 1 && !displayPasswordField"
                                [disabled]="isRO" (click)="setPassword()">{{dictionary.modifyPassword}}</button>
                                <input *ngIf="displayPasswordField" type="password" [(ngModel)]="pwdValue">
                            </div>
                            <div class="emp-field">
                                <label>{{dictionary.hsReal}}</label>
                                <input type="text" [(ngModel)]="curEmployeeInfo.week_rate_val" disabled>
                            </div>
                        </div>
                        <div id="emp-var-col2" *ngIf="hasTS">
                            <div class="emp-field">
                                <label for="chk-paid-flag">{{dictionary.educosExport}} </label>
                                <input type="checkbox" id="chk-paid-flag" [disabled]="isRO"
                                    [(ngModel)]="curEmployeeInfo.export_paid_flag">
                            </div>
                            <div class="emp-field">
                                <label for="chk-holiday-flag">{{dictionary.HRExport}}</label>
                                <input type="checkbox" id="chk-holiday-flag" [disabled]="isRO"
                                    [(ngModel)]="curEmployeeInfo.export_holiday_flag">
                            </div>
                            <div class="emp-field">
                                <label for="chk-conn-flag">{{dictionary.connectionSync}}</label>
                                <input type="checkbox" id="chk-conn-flag" [disabled]="isRO"
                                    [(ngModel)]="curEmployeeInfo.connection_sync_flag">
                            </div>
                            <div class="emp-field">
                                <label for="chk-pylon-flag">{{dictionary.labelPylon}}</label>
                                <input type="checkbox" id="chk-pylon-flag" [disabled]="isRO" [(ngModel)]="curEmployeeInfo.pylon_flag">
                            </div>
                            <div class="emp-field">
                                <label for="chk-astr-flag">{{dictionary.onCall}}</label>
                                <input type="checkbox" id="chk-astr-flag" [disabled]="isRO" [(ngModel)]="curEmployeeInfo.astreinte_flag">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="emp-sup-param" class="grh-section emp-item" *ngIf="hasTS">
                <h2 class="grh-section-title" (click)="toggleSection('emp-sup-param')">
                    <label>
                        <span *ngIf="!isSectionOpened('emp-sup-param')" class="caret"><i class="fa fa-caret-right"></i></span>
                        <span *ngIf="isSectionOpened('emp-sup-param')" class="caret"><i class="fa fa-caret-down"></i></span>
                        <span>{{dictionary.supplementDivisionPreferences}}</span>
                    </label>
                </h2>
                <!--
                <div class="grh-section-content" *ngIf="!isLoadingPersonSuppParams && !curEmployeeSuppParams.length">
                    <span>No data ! </span>
                </div>
                -->
                <div class="grh-section-content" *ngIf="curEmployeeSuppParams.length && isSectionOpened('emp-sup-param')">
                    <div class="supp-params" *ngFor="let employeeSuppParam of curEmployeeSuppParams">
                        <label>{{employeeSuppParam.supp_label}}</label>
                        <div class="sp-datas" *ngIf="!employeeSuppParam.enable_base && !employeeSuppParam.enable_supp">
                            <span class="greyed">Settings on company</span>
                        </div>
                        <div class="sp-datas">
                            <div class="sp-line" *ngIf="employeeSuppParam.configurable_base_flag && employeeSuppParam.enable_base">
                                <label class="tag base">base</label>
                                <div class="sp-radios">
                                    <input id="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-base-type-report" name="{{employeeSuppParam.person_supplements_param_id}}_{{employeeSuppParam.supplement_type_id}}_paid_base_type" type="radio"
                                        [disabled]="isRO" [(ngModel)]="employeeSuppParam.paid_base_type" value="REPORT">
                                    <label for="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-base-type-report">{{dictionary.time}}</label>
                                    <input id="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-base-type-paid" name="{{employeeSuppParam.person_supplements_param_id}}_{{employeeSuppParam.supplement_type_id}}_paid_base_type" type="radio"
                                        [disabled]="isRO" [(ngModel)]="employeeSuppParam.paid_base_type" value="PAID">
                                        <label for="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-base-type-paid">{{dictionary.pay}}</label>
                                    <input id="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-base-type-mixed" name="{{employeeSuppParam.person_supplements_param_id}}_{{employeeSuppParam.supplement_type_id}}_paid_base_type" type="radio"
                                        [disabled]="isRO" [(ngModel)]="employeeSuppParam.paid_base_type" value="MIXTE">
                                        <label for="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-base-type-mixed">{{dictionary.payPercent}}</label>
                                    <input type="text" *ngIf="employeeSuppParam.paid_base_type == 'MIXTE'" [(ngModel)]="employeeSuppParam.paid_base_procent">
                                </div>
                            </div>
                            <div class="sp-line" *ngIf="employeeSuppParam.configurable_sup_flag && employeeSuppParam.enable_supp">
                                <label class="tag {{employeeSuppParam.supplement_type_id == 3 ? 'all' : 'supp'}}">{{employeeSuppParam.supplement_type_id == 3 ? 'all' : 'supp'}}</label>
                                <div class="sp-radios">
                                    <input id="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-type-report" name="{{employeeSuppParam.person_supplements_param_id}}_{{employeeSuppParam.supplement_type_id}}_paid_type" type="radio"
                                        [disabled]="isRO" [(ngModel)]="employeeSuppParam.paid_supp_type" value="REPORT">
                                        <label for="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-type-report">{{dictionary.time}}</label>
                                    <input id="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-type-paid" name="{{employeeSuppParam.person_supplements_param_id}}_{{employeeSuppParam.supplement_type_id}}_paid_type" type="radio"
                                        [disabled]="isRO" [(ngModel)]="employeeSuppParam.paid_supp_type" value="PAID">
                                        <label for="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-type-paid">{{dictionary.pay}}</label>
                                    <input id="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-type-mixed" name="{{employeeSuppParam.person_supplements_param_id}}_{{employeeSuppParam.supplement_type_id}}_paid_type" type="radio"
                                        [disabled]="isRO" [(ngModel)]="employeeSuppParam.paid_supp_type" value="MIXTE">
                                        <label for="{{employeeSuppParam.person_supplements_param_id}}-{{employeeSuppParam.supplement_type_id}}-paid-type-mixed">{{dictionary.payPercent}}</label>
                                    <input type="text" *ngIf="employeeSuppParam.paid_supp_type == 'MIXTE'" [(ngModel)]="employeeSuppParam.paid_supp_procent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="emp-right">
            <div id="emp-hist" class="grh-section emp-item" *ngIf="hasHR">
                <h2 class="grh-section-title">
                    <label>
                        <span>{{dictionary.historyOfSig}}</span>
                    </label>
                </h2>
                <div class="grh-section-content">
                    <div id="emp-hist-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>{{dictionary.field}}</th>
                                    <th>{{dictionary.value}}</th>
                                    <th>{{dictionary.from}}</th>
                                    <th>{{dictionary.to}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let line of curSigHistory" [class.inactive]="isActiveEl(line)"
                                    [class.future]="isFutureEl(line)" [class.past]="isPastEl(line)">
                                    <td>{{line.signal_field_name}}</td>
                                    <td>{{line.signal_field_value}}</td>
                                    <td>{{line.from_date | date : 'dd/MM/yyyy'}}</td>
                                    <td>{{line.to_date | date : 'dd/MM/yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="emp-bottombar">
        <div id="left-btn-grp" class="button-group">
            <button *ngIf="!newEmployee && !newContract" [disabled]="isRO" (click)="showPeopleContract()"> {{dictionary.new}}
                {{dictionary.newContractEmp}}</button>
            <button *ngIf="newEmployee != null || newContract != null" (click)="cancelAction()">{{dictionary.cancel}}</button>
            <button [disabled]="isRO || !formEmployeeInfo.valid" (click)="saveEmployee()">{{dictionary.tmgSave}}</button>
            <button *ngIf="!newEmployee && !newContract && isUserSA"
                (click)="delete()">{{dictionary.titleDelete}}</button>
        </div>
    </div>
</div>