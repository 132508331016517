<div class="pubHol">
    <div class="pubHol-header">

        <button (click)="openPublicHoliday()">Charger les données</button>
        <button (click)="openTypePublicHoliday()">Ajouter un nouveau type de férié</button>
        <button (click)="onChangeCompany()"></button>
    </div>
    <div class="pubHol-main">
        <div>
            <select [(ngModel)]="curYear" (change)="onChangeYear()">
                <option *ngFor="let year of listOfYears">{{year}}</option>
            </select> 

            <select [(ngModel)]="selectedCompanies" multiple (change)="onChangeCompany()">
                <option *ngFor="let comp of listOfCompaniesCB" [ngValue]="comp.company_displayname">{{comp.company_displayname}}</option>
            </select>
        </div>
        <div id="pubHol-main-table" *ngIf="displayTable">
            <div class="local-loader" *ngIf="publicHolidayLoading">
                <img src="./assets/img/loader.gif" alt="loading...">
            </div>
            <table *ngIf="!publicHolidayLoading"> 
                <thead>
                    <tr>
                        <th>Description:</th>
                        <th *ngFor="let current of listOfCompanies[0]">{{current.company_displayname}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of listOfPublicHolidays[0]"  (click)="selectPublicHolidayType(line)">
                        <td>{{line.holiday_description}}</td>

                        <td *ngFor="let a of listOfCompanies[0]; let iteration = index">
                            <input type="date" [ngModel] ="line[a.company_id] | date:'yyyy-MM-dd'" (change)="onDateCellChange(line,a,$event)" (ngModelChange)="line[a.company_id] = $event"> 
                        </td>              
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<div *ngIf="displayPpAddNewTypePublicHoliday" class="fullsize-popup-container">
    <div id="ppl-main">
        <div id="ppl-header">
            <span class="ppl-header-title">
                <img>
                <label>Recherche type de holiday</label>
            </span>
            <button (click)="ClosePopupType()">X</button>
        </div>
        <div id="ppl-inter">
            <button (click)="addNewTypePublicHolidayToDB()">Ajouter</button>
            <button (click)="updatePublicHolidayType()">Modifier</button>
            <button (click)="deletePublicHolidayType()">Supprimer</button>
        </div>
        <div id="ppl-list" class="noselect">
            <div class="local-loader" *ngIf="publicHolidayTypeLoading">
                <img src="./assets/img/loader.gif" alt="loading...">
            </div>
            <table *ngIf="!publicHolidayTypeLoading">
                <thead>
                    <tr>
                        <th>Description:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let line of listOfPublicHolidayType[0]" [class.selected]="line.holiday_id == selectedPublicHolidayType?.holiday_id" (click)="selectPublicHolidayType(line)">
                        <td>{{line.holiday_desc}}</td>
                    </tr>
                </tbody>
            </table>
        </div>   
        <div id="ppl-bottom">
            <button (click)="ClosePopupType()">Fermer</button>            
        </div>
    </div>
</div>
