import { Injectable } from '@angular/core';
import { CompanyDict, ContractDict, DepartmentDict, EmployeeDict, PeopleDict, Profile, ProfileDict, ServiceDict, SignaleticDict, ToolDict, DelegationDict, LogDict } from '@app/models';
import { TmsApiService } from './tms-api.service';

@Injectable({
  providedIn: 'root'
})
export class DictService {

  private completeDict: any;
  private toolDict: ToolDict;
  private signaleticDict: SignaleticDict;
  private companyDict: CompanyDict;
  private departmentDict: DepartmentDict;
  private serviceDict: ServiceDict;
  private employeeDict: EmployeeDict;
  private peopleDict: PeopleDict;
  private contractDict: ContractDict;
  private profileDict: ProfileDict;
  private delegationDict: DelegationDict;
  private logDict: LogDict;
  private isLoaded: boolean;

  constructor(private tmsApiService: TmsApiService) {
    this.completeDict = null;
    this.isLoaded = false;
  }

  public get dicoIsLoaded(): boolean {
    return this.isLoaded;
  }

  public getSignaleticDict() {
    return this.signaleticDict;
  }

  public getCompanycDict() {
    return this.companyDict;
  }

  public getDepartmentDict() {
    return this.departmentDict;
  }

  public getServiceDict() {
    return this.serviceDict;
  }

  public getEmployeeDict() {
    return this.employeeDict;
  }

  public getPeopleDict() {
    return this.peopleDict;
  }

  public getContractDict() {
    return this.contractDict;
  }

  public getToolDict() {
    return this.toolDict;
  }

  public getProfileDict() {
    return this.profileDict;
  }

  public getDelegationDict() {
    return this.delegationDict;
  }

  public getLogDict() {
    return this.logDict;
  }

  public loadDict() {
    this.tmsApiService.getDico().subscribe((response) => {
      this.completeDict = response;
      this.setDictionnaries();
      this.isLoaded = true;
    });

  }

  private cleanString(aValue: string): string {
    let theResult: string = aValue;
    if (theResult !== undefined) {
      theResult = theResult.replace(/&/, '');
      theResult = theResult.replace(/"/g, '');
      theResult = theResult.replace('\n', '');
      theResult = theResult.trim();
    }
    return theResult;
  }

  private setDictionnaries() {

    //TOOL SELECTOR
    this.toolDict = new ToolDict();
    this.toolDict.report = this.cleanString(this.completeDict[1416]);
    this.toolDict.selectTool = this.cleanString(this.completeDict[3196]);
    this.toolDict.signaletics = this.cleanString(this.completeDict[1050]);
    this.toolDict.profilesManagement = this.cleanString(this.completeDict[1060]);
    this.toolDict.delegations = this.cleanString(this.completeDict[1771]);
    this.toolDict.logs = this.cleanString(this.completeDict[1009]);
    this.toolDict.publicHolidays = this.cleanString(this.completeDict[3231]);

    //PROFILES - PROFILE EDITOR
    this.profileDict = new ProfileDict();
    this.profileDict.profilesWindows = this.cleanString(this.completeDict[38]);
    this.profileDict.profilesUsers = this.cleanString(this.completeDict[48]);
    this.profileDict.onTimeEdition = this.cleanString(this.completeDict[1613]);
    this.profileDict.enterProfileName = this.cleanString(this.completeDict[1615]);
    this.profileDict.edit = this.cleanString(this.completeDict[1614]);
    this.profileDict.save = this.cleanString(this.completeDict[146]);
    this.profileDict.userProfiles = this.cleanString(this.completeDict[46]);
    this.profileDict.new = this.cleanString(this.completeDict[134]);
    this.profileDict.profiles = this.cleanString(this.completeDict[47]);
    this.profileDict.linkedWindows = this.cleanString(this.completeDict[42]);
    this.profileDict.total = this.cleanString(this.completeDict[45]);
    this.profileDict.mode = this.cleanString(this.completeDict[43]);
    this.profileDict.readonly = this.cleanString(this.completeDict[44]);
    this.profileDict.windows = this.cleanString(this.completeDict[41]);
    this.profileDict.onTimeWindows = this.cleanString(this.completeDict[40]);
    this.profileDict.onTimeUsers = this.cleanString(this.completeDict[53]);
    this.profileDict.users = this.cleanString(this.completeDict[54]);
    this.profileDict.linkedProfiles = this.cleanString(this.completeDict[49]);
    this.profileDict.profile_s = this.cleanString(this.completeDict[47]);
    this.profileDict.profile = this.cleanString(this.completeDict[50]);
    this.profileDict.showDeletedProfiles = this.cleanString(this.completeDict[39]);
    this.profileDict.showInactiveUsers = this.cleanString(this.completeDict[51]);
    this.profileDict.labelSearch = this.cleanString(this.completeDict[32]); 

    //DELEGATION
    this.delegationDict = new DelegationDict();
    this.delegationDict.timesheet = this.cleanString(this.completeDict[1772]);
    this.delegationDict.menuLabelPlanning = this.cleanString(this.completeDict[513]);
    this.delegationDict.myDelegates = this.cleanString(this.completeDict[1086]);
    this.delegationDict.mySupplementaryRights = this.cleanString(this.completeDict[1084]);
    this.delegationDict.new = this.cleanString(this.completeDict[134]);
    this.delegationDict.holder = this.cleanString(this.completeDict[1088]);
    this.delegationDict.delegate = this.cleanString(this.completeDict[1089]);
    this.delegationDict.start = this.cleanString(this.completeDict[1774]);
    this.delegationDict.end = this.cleanString(this.completeDict[1775]);
    this.delegationDict.newRightsDelegation = this.cleanString(this.completeDict[1087]);
    this.delegationDict.from = this.cleanString(this.completeDict[1090]);
    this.delegationDict.until = this.cleanString(this.completeDict[1091]);
    this.delegationDict.tmgSave = this.cleanString(this.completeDict[146]);
    this.delegationDict.showInactive = this.cleanString(this.completeDict[1401]);
    this.delegationDict.labelSearch = this.cleanString(this.completeDict[32]);
    this.delegationDict.deleteDelegQuestion = this.cleanString(this.completeDict[1900]);

    //LOGS
    this.logDict = new LogDict();
    this.logDict.logType = "";
    this.logDict.people = "";
    this.logDict.year = "";
    this.logDict.period = "";
    this.logDict.from = "";
    this.logDict.to = "";

    //SIGNALETICS
    this.signaleticDict = new SignaleticDict();
    this.signaleticDict.signaletics = this.cleanString(this.completeDict[1050]);
    this.signaleticDict.inactivePeopleEntities = this.cleanString(this.completeDict[3168]);
    this.signaleticDict.labelSearch = this.cleanString(this.completeDict[32]);
    this.signaleticDict.french = this.cleanString(this.completeDict[3]);
    this.signaleticDict.english = this.cleanString(this.completeDict[4]);
    this.signaleticDict.logout = this.cleanString(this.completeDict[1867]);
    this.signaleticDict.options = this.cleanString(this.completeDict[1]);
    this.signaleticDict.currentLang = this.cleanString(this.completeDict[1902]);
    this.signaleticDict.changeLine = this.cleanString(this.completeDict[3186]);
    this.signaleticDict.finished = this.cleanString(this.completeDict[853]);
    this.signaleticDict.tool = this.cleanString(this.completeDict[3197]);
    this.signaleticDict.report = this.cleanString(this.completeDict[1416]);
    this.signaleticDict.profilesManagement = this.cleanString(this.completeDict[1060]);
    this.signaleticDict.delegations = this.cleanString(this.completeDict[1000]);
    this.signaleticDict.publicHolidays = this.cleanString(this.completeDict[3231]);

    //PEOPLE & PEOPLE CONTRACT
    this.peopleDict = new PeopleDict();
    this.peopleDict.searchingForAPerson = this.cleanString(this.completeDict[573]);
    this.peopleDict.labelSearch = this.cleanString(this.completeDict[32]);
    this.peopleDict.newPerson = this.cleanString(this.completeDict[483]);
    this.peopleDict.selectAPersonInTheList = this.cleanString(this.completeDict[575]);
    this.peopleDict.employeesList = this.cleanString(this.completeDict[576]);
    this.peopleDict.rptName = this.cleanString(this.completeDict[1316]);
    this.peopleDict.HRCode = this.cleanString(this.completeDict[3169]);
    this.peopleDict.inTimesheet = this.cleanString(this.completeDict[3170]);
    this.peopleDict.archived = this.cleanString(this.completeDict[143]);
    this.peopleDict.tmgShowInactPpl = this.cleanString(this.completeDict[207]);
    this.peopleDict.select = this.cleanString(this.completeDict[574]);

    // MOVE CONTRACT
    this.contractDict = new ContractDict();
    this.contractDict.moveContract = this.cleanString(this.completeDict[3183]);
    this.contractDict.department = this.cleanString(this.completeDict[494]);
    this.contractDict.service = this.cleanString(this.completeDict[514]);
    this.contractDict.cancel = this.cleanString(this.completeDict[133]);
    this.contractDict.validate = this.cleanString(this.completeDict[1741]);

    //COMPANY
    this.companyDict = new CompanyDict();
    this.companyDict.sheets = this.cleanString(this.completeDict[1876]);
    this.companyDict.name = this.cleanString(this.completeDict[533]);
    this.companyDict.displayName = this.cleanString(this.completeDict[3188]);
    this.companyDict.abbreviation = this.cleanString(this.completeDict[467]);
    this.companyDict.domain = this.cleanString(this.completeDict[3189]);
    this.companyDict.fullTimeWeekHours = this.cleanString(this.completeDict[3206]);
    this.companyDict.quarterRound = this.cleanString(this.completeDict[3190]);
    this.companyDict.hasTimesheet = this.cleanString(this.completeDict[3191]);
    this.companyDict.hasPlanning = this.cleanString(this.completeDict[3230]);
    this.companyDict.hasHRInterface = this.cleanString(this.completeDict[3192]);
    this.companyDict.archived = this.cleanString(this.completeDict[143]);
    this.companyDict.newCompany = this.cleanString(this.completeDict[1381]);
    this.companyDict.cancel = this.cleanString(this.completeDict[133]);
    this.companyDict.fillInName = this.cleanString(this.completeDict[1818]);
    this.companyDict.fillAbbreviationIn = this.cleanString(this.completeDict[1895]);
    this.companyDict.fillDisplayNameIn = this.cleanString(this.completeDict[3193]);
    this.companyDict.abbrMaxChars = this.cleanString(this.completeDict[3194]);
    this.companyDict.companyAlreadyExists = this.cleanString(this.completeDict[3195]);
    this.companyDict.newDepartment = this.cleanString(this.completeDict[485]);
    this.companyDict.tmgSave = this.cleanString(this.completeDict[146]);
    this.companyDict.titleDelete = this.cleanString(this.completeDict[147]);
    this.companyDict.supplementProfile = this.cleanString(this.completeDict[3199]);
    this.companyDict.noProfile = this.cleanString(this.completeDict[3200]);

    //DEPARTMENT
    this.departmentDict = new DepartmentDict();
    this.departmentDict.sheets = this.cleanString(this.completeDict[1876]);
    this.departmentDict.rptName = this.cleanString(this.completeDict[533]);
    this.departmentDict.abreviation = this.cleanString(this.completeDict[467]);
    this.departmentDict.responsible = this.cleanString(this.completeDict[554]);
    this.departmentDict.archived = this.cleanString(this.completeDict[143]);
    this.departmentDict.mailPeriodEnd = this.cleanString(this.completeDict[3163]);
    this.departmentDict.mailReminderVal2 = this.cleanString(this.completeDict[3161]);
    this.departmentDict.delayValLimit = this.cleanString(this.completeDict[3154]);
    this.departmentDict.labelDays = this.cleanString(this.completeDict[1397]);
    this.departmentDict.frequency = this.cleanString(this.completeDict[3156]);
    this.departmentDict.once = this.cleanString(this.completeDict[3166]);
    this.departmentDict.everyday = this.cleanString(this.completeDict[3165]);
    this.departmentDict.onlyIfSup = this.cleanString(this.completeDict[3164]);
    this.departmentDict.mailAllSrvVal = this.cleanString(this.completeDict[3159]);
    this.departmentDict.recipient = this.cleanString(this.completeDict[1940]);
    this.departmentDict.timeManagers = this.cleanString(this.completeDict[556]);
    this.departmentDict.controllers = this.cleanString(this.completeDict[567]);
    this.departmentDict.adminsRH = this.cleanString(this.completeDict[465]);
    this.departmentDict.GDTCTR = this.cleanString(this.completeDict[3157]);
    this.departmentDict.members = this.cleanString(this.completeDict[560]);
    this.departmentDict.rptExport = this.cleanString(this.completeDict[487]);
    this.departmentDict.newService = this.cleanString(this.completeDict[484]);
    this.departmentDict.new = this.cleanString(this.completeDict[134]);
    this.departmentDict.tmgSave = this.cleanString(this.completeDict[146]);
    this.departmentDict.titleDelete = this.cleanString(this.completeDict[147]);
    this.departmentDict.cancel = this.cleanString(this.completeDict[133]);
    this.departmentDict.nameOrAbbreviationExistsAlready = this.cleanString(this.completeDict[1894]);
    this.departmentDict.fillInName = this.cleanString(this.completeDict[1818]);
    this.departmentDict.fillAbbreviationIn = this.cleanString(this.completeDict[1895]);
    this.departmentDict.deleteDepartmentQuestion = this.cleanString(this.completeDict[1898]);
    this.departmentDict.confirmDeleteRole = this.cleanString(this.completeDict[3187]);

    //SERVICE
    this.serviceDict = new ServiceDict();
    this.serviceDict.record = this.cleanString(this.completeDict[865]);
    this.serviceDict.rptName = this.cleanString(this.completeDict[533]);
    this.serviceDict.tmgArchived = this.cleanString(this.completeDict[143]);
    this.serviceDict.sectionN = this.cleanString(this.completeDict[559]);
    this.serviceDict.responsible = this.cleanString(this.completeDict[554]);
    this.serviceDict.taskMgmtDesc = this.cleanString(this.completeDict[136]);
    this.serviceDict.timeManagers = this.cleanString(this.completeDict[556]);
    this.serviceDict.new = this.cleanString(this.completeDict[134]);
    this.serviceDict.labelOther = this.cleanString(this.completeDict[541]);
    this.serviceDict.validationLevel = this.cleanString(this.completeDict[1566]);
    this.serviceDict.rptServ = this.cleanString(this.completeDict[514]);
    this.serviceDict.department = this.cleanString(this.completeDict[494]);
    this.serviceDict.yearPeriod = this.cleanString(this.completeDict[555]);
    this.serviceDict.moveToAYearPeriod = this.cleanString(this.completeDict[563]);
    this.serviceDict.fromPeriod = this.cleanString(this.completeDict[460]);
    this.serviceDict.typeOfPeriod = this.cleanString(this.completeDict[1574]);
    this.serviceDict.fourWeeks = this.cleanString(this.completeDict[1872]);
    this.serviceDict.fourWeeksPeriod = this.cleanString(this.completeDict[561]);
    this.serviceDict.oneYear = this.cleanString(this.completeDict[1873]);
    this.serviceDict.oneYearPeriod = this.cleanString(this.completeDict[555]);
    this.serviceDict.perfomedTasksEncoding = this.cleanString(this.completeDict[1371]);
    this.serviceDict.onlyExtraHours = this.cleanString(this.completeDict[1874]);
    this.serviceDict.periodEncoding = this.cleanString(this.completeDict[562]);
    this.serviceDict.labelInvoicedTime = this.cleanString(this.completeDict[565]);
    this.serviceDict.serviceAutomanaged = this.cleanString(this.completeDict[3142]);
    this.serviceDict.timePeriod = this.cleanString(this.completeDict[564]);
    this.serviceDict.default = this.cleanString(this.completeDict[566]);
    this.serviceDict.AM = this.cleanString(this.completeDict[419]);
    this.serviceDict.PM = this.cleanString(this.completeDict[520]);
    this.serviceDict.mailPeriodEnd = this.cleanString(this.completeDict[3163]);
    this.serviceDict.mailReminderEncoder = this.cleanString(this.completeDict[3162]);
    this.serviceDict.delayPeriodEnd = this.cleanString(this.completeDict[3153]);
    this.serviceDict.days = this.cleanString(this.completeDict[3167]);
    this.serviceDict.recipient = this.cleanString(this.completeDict[1940]);
    this.serviceDict.employee = this.cleanString(this.completeDict[3155]);
    this.serviceDict.supervisor = this.cleanString(this.completeDict[1924]);
    this.serviceDict.frequency = this.cleanString(this.completeDict[3156]);
    this.serviceDict.once = this.cleanString(this.completeDict[3166]);
    this.serviceDict.everyday = this.cleanString(this.completeDict[3165]);
    this.serviceDict.mailReminderVal1 = this.cleanString(this.completeDict[3160]);
    this.serviceDict.onlyIfSup = this.cleanString(this.completeDict[3164]);
    this.serviceDict.serviceParameters = this.cleanString(this.completeDict[1913]);
    this.serviceDict.labelWorkHours = this.cleanString(this.completeDict[1245]);
    this.serviceDict.labelOvertime = this.cleanString(this.completeDict[1246]);
    this.serviceDict.labelBankHoliday = this.cleanString(this.completeDict[1247]);
    this.serviceDict.labelNightHours = this.cleanString(this.completeDict[1248]);
    this.serviceDict.labelOnCallHours = this.cleanString(this.completeDict[1249]);
    this.serviceDict.labelWorkHoursOnCall = this.cleanString(this.completeDict[1250]);
    this.serviceDict.labelSundayHours = this.cleanString(this.completeDict[1251]);
    this.serviceDict.labelNights = this.cleanString(this.completeDict[1252]);
    this.serviceDict.labelDaysAbroad = this.cleanString(this.completeDict[1253]);
    this.serviceDict.labelHPylone = this.cleanString(this.completeDict[1254]);
    this.serviceDict.labelMPylone = this.cleanString(this.completeDict[1255]);
    this.serviceDict.labelFMaj = this.cleanString(this.completeDict[1256]);
    this.serviceDict.labelFMajYrRest = this.cleanString(this.completeDict[1257]);
    this.serviceDict.initGrid = this.cleanString(this.completeDict[558]);
    this.serviceDict.total = this.cleanString(this.completeDict[521]);
    this.serviceDict.newContractEmp = this.cleanString(this.completeDict[3175]);
    this.serviceDict.tmgSave = this.cleanString(this.completeDict[146]);
    this.serviceDict.titleDelete = this.cleanString(this.completeDict[147]);
    this.serviceDict.cancel = this.cleanString(this.completeDict[133]);
    this.serviceDict.nameAlreadyExist = this.cleanString(this.completeDict[1891]);
    this.serviceDict.fillServiceName = this.cleanString(this.completeDict[1892]);
    this.serviceDict.serviceManagerNotSpecified = this.cleanString(this.completeDict[1893]);
    this.serviceDict.deleteServiceQuestion = this.cleanString(this.completeDict[1897]);
    this.serviceDict.confirmDeleteRole = this.cleanString(this.completeDict[3187]);


    //EMPLOYEE
    this.employeeDict = new EmployeeDict();
    this.employeeDict.identity = this.cleanString(this.completeDict[530]);
    this.employeeDict.rptName = this.cleanString(this.completeDict[533]);
    this.employeeDict.firstname = this.cleanString(this.completeDict[531]);
    this.employeeDict.mail = this.cleanString(this.completeDict[532]);
    this.employeeDict.externalMail = this.cleanString(this.completeDict[3221]);
    this.employeeDict.login = this.cleanString(this.completeDict[534]);
    this.employeeDict.sex = this.cleanString(this.completeDict[535]);
    this.employeeDict.title = this.cleanString(this.completeDict[536]);
    this.employeeDict.madam = this.cleanString(this.completeDict[537]);
    this.employeeDict.mister = this.cleanString(this.completeDict[538]);
    this.employeeDict.miss = this.cleanString(this.completeDict[1059]);
    this.employeeDict.mat = this.cleanString(this.completeDict[539]);
    this.employeeDict.SF = this.cleanString(this.completeDict[540]);
    this.employeeDict.services = this.cleanString(this.completeDict[1870]);
    this.employeeDict.move = this.cleanString(this.completeDict[3182]);
    this.employeeDict.company = this.cleanString(this.completeDict[512]);
    this.employeeDict.department = this.cleanString(this.completeDict[494]);
    this.employeeDict.masterService = this.cleanString(this.completeDict[505]);
    this.employeeDict.status = this.cleanString(this.completeDict[498]);
    this.employeeDict.scale = this.cleanString(this.completeDict[1054]);
    this.employeeDict.offScale = this.cleanString(this.completeDict[1055]);
    this.employeeDict.pigist = this.cleanString(this.completeDict[1056]);
    this.employeeDict.intern = this.cleanString(this.completeDict[1057]);
    this.employeeDict.apprentice = this.cleanString(this.completeDict[1058]);
    this.employeeDict.menuLabelPlanning = this.cleanString(this.completeDict[513]);
    this.employeeDict.labelFunction = this.cleanString(this.completeDict[499]);
    this.employeeDict.labelTask = this.cleanString(this.completeDict[500]);
    this.employeeDict.entryDate = this.cleanString(this.completeDict[501]);
    this.employeeDict.workRates = this.cleanString(this.completeDict[861]);
    this.employeeDict.initPresta = this.cleanString(this.completeDict[517]);
    this.employeeDict.rptServ = this.cleanString(this.completeDict[514]);
    this.employeeDict.rptPers = this.cleanString(this.completeDict[515]);
    this.employeeDict.nothing = this.cleanString(this.completeDict[516]);
    this.employeeDict.outDate = this.cleanString(this.completeDict[502]);
    this.employeeDict.periodeEncoding = this.cleanString(this.completeDict[504]);
    this.employeeDict.initGrid = this.cleanString(this.completeDict[558]);
    this.employeeDict.total = this.cleanString(this.completeDict[521]);
    this.employeeDict.labelOther = this.cleanString(this.completeDict[541]);
    this.employeeDict.connection = this.cleanString(this.completeDict[548]);
    this.employeeDict.modifyPassword = this.cleanString(this.completeDict[1903]);
    this.employeeDict.hsTheo = this.cleanString(this.completeDict[549]);
    this.employeeDict.hsReal = this.cleanString(this.completeDict[529]);
    this.employeeDict.hsupSUP = this.cleanString(this.completeDict[1458]);
    this.employeeDict.time = this.cleanString(this.completeDict[1461]);
    this.employeeDict.pay = this.cleanString(this.completeDict[1459]);
    this.employeeDict.payPercent = this.cleanString(this.completeDict[572]);
    this.employeeDict.hsupBASE = this.cleanString(this.completeDict[1457]);
    this.employeeDict.astrSUP = this.cleanString(this.completeDict[1456]);
    this.employeeDict.astrBASE = this.cleanString(this.completeDict[1455]);
    this.employeeDict.fmajSUP = this.cleanString(this.completeDict[1454]);
    this.employeeDict.fmajBASE = this.cleanString(this.completeDict[1453]);
    this.employeeDict.bankHolidaySUP = this.cleanString(this.completeDict[1452]);
    this.employeeDict.educosExport = this.cleanString(this.completeDict[544]);
    this.employeeDict.HRExport = this.cleanString(this.completeDict[545]);
    this.employeeDict.connectionSync = this.cleanString(this.completeDict[3133]);
    this.employeeDict.labelPylon = this.cleanString(this.completeDict[546]);
    this.employeeDict.onCall = this.cleanString(this.completeDict[547]);
    this.employeeDict.new = this.cleanString(this.completeDict[134]);
    this.employeeDict.newContractEmp = this.cleanString(this.completeDict[3175]);
    this.employeeDict.tmgSave = this.cleanString(this.completeDict[146]);
    this.employeeDict.titleDelete = this.cleanString(this.completeDict[147]);
    this.employeeDict.cancel = this.cleanString(this.completeDict[133]);
    this.employeeDict.matriculeUsed = this.cleanString(this.completeDict[1878]);
    this.employeeDict.loginUsed = this.cleanString(this.completeDict[1879]);
    this.employeeDict.emailUsed = this.cleanString(this.completeDict[1880]);
    this.employeeDict.fillInName = this.cleanString(this.completeDict[1818]);
    this.employeeDict.fillInLogin = this.cleanString(this.completeDict[1509]);
    this.employeeDict.fillInTitle = this.cleanString(this.completeDict[1393]);
    this.employeeDict.fillInMatricule = this.cleanString(this.completeDict[1881]);
    this.employeeDict.externalCodeNotValid = this.cleanString(this.completeDict[1882]);
    this.employeeDict.fillInPassword = this.cleanString(this.completeDict[1904]);
    this.employeeDict.isNotCorrectlyFilled = this.cleanString(this.completeDict[1884]);
    this.employeeDict.isNotCorrectlySpeciefied = this.cleanString(this.completeDict[1886]);
    this.employeeDict.masterServiceForDateOfContract = this.cleanString(this.completeDict[1890]);
    this.employeeDict.onlyOneMasterService = this.cleanString(this.completeDict[1722]);
    this.employeeDict.invalidTimeInterval = this.cleanString(this.completeDict[1912]);
    this.employeeDict.timeIntervalsOverlap = this.cleanString(this.completeDict[1910]);
    this.employeeDict.chooseTask = this.cleanString(this.completeDict[1911]);
    this.employeeDict.contractNumberStatus = this.cleanString(this.completeDict[1883]);
    this.employeeDict.entryDateOfContractN = this.cleanString(this.completeDict[1885]);
    this.employeeDict.occupationRateContractNumber = this.cleanString(this.completeDict[1887]);
    this.employeeDict.entryAndExitDateOfCN = this.cleanString(this.completeDict[1888]);
    this.employeeDict.deletePersonQuestion = this.cleanString(this.completeDict[1896]);
    this.employeeDict.historyOfSig = this.cleanString(this.completeDict[1916]);
    this.employeeDict.field = this.cleanString(this.completeDict[1918]);
    this.employeeDict.value = this.cleanString(this.completeDict[1919]);
    this.employeeDict.from = this.cleanString(this.completeDict[823]);
    this.employeeDict.to = this.cleanString(this.completeDict[138]);
    this.employeeDict.supplementDivisionPreferences = this.cleanString(this.completeDict[3203]);
    this.employeeDict.btnGenerate = this.cleanString(this.completeDict[3222]);  //LAURENT

    //this.employeeDict.entryDateOfContractN = this.cleanString(this.completeDict[1885]);
    //this.employeeDict.isNotCorrectlySpeciefied = this.cleanString(this.completeDict[1885]);

  }




}
