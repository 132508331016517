import { Item } from "./item";

export class Employee extends Item {
    personId: number;
    externalUserCode: string;
    fullname: string;
    serviceId: number;
    serviceName: string;
    departmentId: number;
    departmentName: string;
    companyId: number;
    companyName: string;

    constructor(aPersId: number, anExtUsCode: string, aFuName: string, aServId: number, aServName: string,
        aDepId: number, aDepName: string, aCompId: number, aCompName: string, aFlag: boolean, aHasTS: boolean, aHasHR: boolean, aHasPark: boolean) {
        super(aFlag, aHasTS, aHasHR,aHasPark);
        this.personId = aPersId;
        this.externalUserCode = anExtUsCode;
        this.fullname = aFuName;
        this.serviceId = aServId;
        this.serviceName = aServName;
        this.departmentId = aDepId;
        this.departmentName = aDepName;
        this.companyId = aCompId;
        this.companyName = aCompName;

    }
}

export class EmployeeSupplementParam {
    person_supplements_param_id: number;
    supplement_type_id: number;
    shortname: string;
    configurable_base_flag: boolean;
    configurable_sup_flag: boolean;
    paid_base_procent: number;
    paid_supp_procent: number;
    paid_base_type: string;
    paid_supp_type: string;
    enable_base: boolean;
    enable_supp: boolean;

    constructor() {
        this.person_supplements_param_id = -1;
        this.supplement_type_id = -1;
        this.shortname = '';
        this.configurable_base_flag = false;
        this.configurable_sup_flag = false;
        this.paid_base_procent = 0;
        this.paid_supp_procent = 0;
        this.paid_base_type = '';
        this.paid_supp_type = '';
        this.enable_base = false;
        this.enable_supp = false;
    }
}

export class EmployeeInfos {
    ad_flag: boolean;
    astreinte_flag: boolean;
    astreinte_pot_flag: boolean;
    connection_sync_flag: boolean;
    create_by: number;
    create_date: string;
    edit_table_flag: boolean;
    email_address: string;
    external_email_address : string; //Laurent
    export_holiday_flag: boolean;
    export_paid_flag: boolean;
    external_code: string;
    external_flag: boolean;
    first_name: string;
    is_deleted_flag: boolean;
    last_name: string;
    login: string;
    matricule_nr: string;
    mod_by: number;
    mod_date: string;
    park_pool_id : number;
    person_id: number;
    pwd_decrypt_val: string;
    pwd_val: string;
    pylon_flag: boolean;
    sex_val: string;
    title_id: number;
    tms_connection_mode_id: number;
    tms_connection_mode_name: string;
    total_rate_val: number;
    week_rate_val: number;

    constructor() { }
}

export class EmployeeGrid {
    count_val: number;
    create_by: number;
    create_date: string;
    day_val: number;
    from_time: string;
    function_desc: string;
    function_id: number;
    grid_id: number;
    is_deleted_flag: boolean;
    mod_by: number;
    mod_date: string;
    person_contract_id: number;
    task_desc: string;
    task_id: number;
    to_time: string;
}

export class EmployeeAutoComp {
    email_address: string;
    external_email_address: string;
    external_code: string;
    first_name: string;
    gender: string;
    last_name: string;
    login: string;
    matricule_nr: string;
    constructor() { }

}

export class EmployeeDict {

    identity: string;
    rptName: string;
    firstname: string;
    mail: string;
    externalMail : string;
    login: string;
    sex: string;
    title: string;
    madam: string;
    mister: string;
    miss: string;
    mat: string;
    SF: string;
    services: string;
    move: string;
    company: string;
    department: string;
    masterService: string;
    status: string;
    scale: string;
    offScale: string;
    pigist: string;
    intern: string;
    apprentice: string;
    menuLabelPlanning: string;
    labelFunction: string;
    labelTask: string;
    entryDate: string;
    workRates: string;
    initPresta: string;
    rptServ: string;
    rptPers: string;
    nothing: string;
    outDate: string;
    periodeEncoding: string;
    initGrid: string;
    total: string;
    labelOther: string;
    connection: string;
    modifyPassword: string;
    hsTheo: string;
    hsReal: string;
    hsupSUP: string;
    time: string;
    pay: string;
    payPercent: string;
    hsupBASE: string;
    astrSUP: string;
    astrBASE: string;
    fmajSUP: string;
    fmajBASE: string;
    bankHolidaySUP: string;
    educosExport: string;
    HRExport: string;
    connectionSync: string;
    labelPylon: string;
    onCall: string;
    new: string;
    newContractEmp: string;
    tmgSave: string;
    titleDelete: string;
    cancel: string;
    matriculeUsed: string;
    loginUsed: string;
    emailUsed: string;
    fillInName: string;
    fillInLogin: string;
    fillInTitle: string;
    fillInMatricule: string;
    externalCodeNotValid: string;
    fillInPassword: string;
    isNotCorrectlyFilled: string;
    isNotCorrectlySpeciefied: string;
    masterServiceForDateOfContract: string;
    onlyOneMasterService: string;
    invalidTimeInterval: string;
    timeIntervalsOverlap: string;
    chooseTask: string;
    contractNumberStatus: string;
    entryDateOfContractN: string;
    occupationRateContractNumber: string;
    entryAndExitDateOfCN: string;
    deletePersonQuestion: string;
    historyOfSig : string;
    field : string;
    value : string;
    from : string;
    to : string;
    supplementDivisionPreferences: string;
    btnGenerate : string;
    entryMasterflagnotOK : string;

    constructor() {
        this.identity = "";
        this.rptName = "";
        this.firstname = "";
        this.mail = "";
        this.externalMail = "";
        this.login = "";
        this.sex = "";
        this.title = "";
        this.madam = "";
        this.mister = "";
        this.miss = "";
        this.mat = "";
        this.SF = "";
        this.services = "";
        this.move = "";
        this.company = "";
        this.department = "";
        this.masterService = "";
        this.status = "";
        this.scale = "";
        this.offScale = "";
        this.pigist = "";
        this.intern = "";
        this.apprentice = "";
        this.menuLabelPlanning = "";
        this.labelFunction = "";
        this.labelTask = "";
        this.entryDate = "";
        this.workRates = "";
        this.initPresta = "";
        this.rptServ = "";
        this.rptPers = "";
        this.nothing = "";
        this.outDate = "";
        this.periodeEncoding = "";
        this.initGrid = "";
        this.total = "";
        this.labelOther = "";
        this.connection = "";
        this.modifyPassword = "";
        this.hsTheo = "";
        this.hsReal = "";
        this.hsupSUP = "";
        this.time = "";
        this.pay = "";
        this.payPercent = "";
        this.hsupBASE = "";
        this.astrSUP = "";
        this.astrBASE = "";
        this.fmajSUP = "";
        this.fmajBASE = "";
        this.bankHolidaySUP = "";
        this.educosExport = "";
        this.HRExport = "";
        this.connectionSync = "";
        this.labelPylon = "";
        this.onCall = "";
        this.new = "";
        this.newContractEmp = "";
        this.tmgSave = "";
        this.titleDelete = "";
        this.cancel = "";
        this.matriculeUsed = "";
        this.loginUsed = "";
        this.emailUsed = "";
        this.fillInName = "";
        this.fillInLogin = "";
        this.fillInTitle = "";
        this.fillInMatricule = "";
        this.externalCodeNotValid = "";
        this.fillInPassword = "";
        this.isNotCorrectlyFilled = "";
        this.isNotCorrectlySpeciefied = "";
        this.masterServiceForDateOfContract = "";
        this.onlyOneMasterService = "";
        this.invalidTimeInterval = "";
        this.timeIntervalsOverlap = "";
        this.chooseTask = "";
        this.contractNumberStatus = "";
        this.entryDateOfContractN = "";
        this.occupationRateContractNumber = "";
        this.entryAndExitDateOfCN = "";
        this.deletePersonQuestion = "";
        this.historyOfSig = "";
        this.field = "";
        this.value = "";
        this.from = "";
        this.to = "";
        this.supplementDivisionPreferences = "";
        this.btnGenerate = "";
        this.entryMasterflagnotOK = "";
    }

}
